<template>
     <div class="service body">
          <div class="container">
               <div class="columns">
                    <div class="column is-half">
                         <div class="vertical-align">
                              <div class="section-header-2">
                                   Electronic Security Solutions
                              </div>
                              <p>
                                   As a world class provider of integrated security solutions, CTL ESS services offers total electronic security
                                   solutions bringing the latest, reliable and proven technologies for Video Surveillance, Access Control, Personal &
                                   Baggage Screening, Vehicle Control and Scanning, Intrusion Detection and Control, Gate and Perimeter Security, etc.
                                   Our solutions are customised for your specific business needs on the principle of reducing wastage, improving
                                   performance efficiency and reducing risk & costs.
                              </p>
                         </div>
                    </div>
                    <div class="column is-half"><img class="sideimg" src="@/assets/images/services/ess.png" alt="" /></div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Benefits of EES System
                              </div>
                              <div>
                                   <p>
                                        CTL ESS Services are designed for adapting innovation, ideas and latest cutting edge technologies to your
                                        particular needs, irrespective of the size of your operation. We assess your security requirements in terms of
                                        scope, placement and systematic linkage. Our electronic security solutions not only help you to protect people
                                        and premises, but also facilitate increase in business efficiency and reduction of overheads. Our security
                                        offerings cover a wide spectrum of specialised domains.
                                   </p>

                                   <p>
                                        Our customisation is configured solely onthe basis of our strategic analysis of the project scope and detailed
                                        understanding of your requirements. Our strategic ESS solutions for you allows for flexibility, scalability,
                                        reliability and control required for comprehensive security management. It is conceived in a way to equip
                                        businesses with the right tools /platform to protect people, secure assets and ensure regulatory compliance by
                                        combining access control, digital video, intrusion and other functions into one powerful system. Modular
                                        hardware and software options make it easy and cost-effective to adapt and advance the system to keep up with
                                        growing business needs.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Specific Solutions
                              </div>
                              <p>
                                   Video Surveillance System<br />
                                   Access Control Systems<br />
                                   Security Management System<br />
                                   Gate & Perimeter Security<br />
                                   Tetra Systems (Walkie Talkie)<br />
                                   Intrusion Detection & Control Systems<br />
                                   Personal & Baggage Screening<br />
                                   Video Analytics with AI<br />
                              </p>

                              <div class="para-large mt-60">
                                   View Projects that have availed CTLs Solution in this category.
                                   <br />
                                   <router-link :to="{name:'PROJECTS'}" class="knowmore">Know More</router-link>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped>
     
</style>
